import axios, { AxiosResponse } from 'axios';
import { Course } from '../models/Course.d';
import { CourseOverview } from '../models/CourseOverview.d';
import { CourseStep } from '../models/CourseStep.d';
import { COURSE_ENDPOINT, MAIN_ENDPOINT } from './urlService';

export async function getCourseByCourseId(courseId: string | number): Promise<Course> {
	const url = `${MAIN_ENDPOINT}/${COURSE_ENDPOINT.GET_COURSES}/${courseId}`;
	return (await axios.get<Course>(url)).data;
}

export async function getCourses(): Promise<Course[]> {
	const url = `${MAIN_ENDPOINT}/${COURSE_ENDPOINT.GET_COURSES}`;
	return (await axios.get<Course[]>(url)).data;
}

export async function getCourseStepByCourseId(
	courseId: string | number,
	token: string
): Promise<AxiosResponse<CourseStep>> {
	const url = `${MAIN_ENDPOINT}/${COURSE_ENDPOINT.GET_COURSES}/${courseId}/steps`;
	return axios.get<CourseStep>(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export async function getCourseStepProgressByUserIdCourseId(
	userId: string | number,
	courseId: string | number,
	token: string
): Promise<any> {
	const url = `${MAIN_ENDPOINT}/${COURSE_ENDPOINT.GET_COURSE_STEP_PROGRESS(userId, courseId)}`;
	return (
		await axios.get<any>(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	).data;
}

export async function getCoursesByUserId(userId: string | number, token: string): Promise<AxiosResponse<Course[]>> {
	const url = `${MAIN_ENDPOINT}/${COURSE_ENDPOINT.GET_USER_COURSES(userId)}`;
	return axios.get<Course[]>(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export async function getCourseOverviewByCourseId(
	courseId: string | number,
	userId?: string | number
): Promise<CourseOverview> {
	const url = `${MAIN_ENDPOINT}/${COURSE_ENDPOINT.GET_COURSE_OVERVIEW(courseId, userId)}`;
	return (await axios.get<CourseOverview>(url)).data;
}
