import React from 'react';
import ReactMarkdown from 'react-markdown';

import './Markdown.scss';

type MarkdownProps = {
	className?: string;
	content: string;
};

function Markdown(props: MarkdownProps): React.ReactElement<MarkdownProps> {
	const { content = '', className } = props;

	return (
		<ReactMarkdown source={content} className={`markdown ${className}`} />
	);
}

export default Markdown;
