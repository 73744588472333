import React from 'react';
import ArrowForward from '../../../assets/images/icons/arrow-forward.svg';
import VerticalBar from '../../../assets/images/icons/vertical-bar.svg';
import ArrowBackward from '../../../assets/images/icons/arrow-backward.svg';

import './BreadcrumbNavigation.scss';

interface BreadcrumbNavigationProps {
	text: (string | undefined)[];
}

interface BreadcrumbItem {
	isLast: boolean;
	text: string;
}

interface BackToAllCourseProps {
	toAllCourseText: string;
}

interface PrevNextLinksProps {
	backText: string;
	nextText: string;
}

export default function CourseNavigation(
	props: BreadcrumbNavigationProps
): React.ReactElement<BreadcrumbNavigationProps> {
	const { text } = props;

	return (
		<div id="CourseNavigationContainer">
			<BreadcrumbNavigation text={text} />
			{/* <PrevNextLinks backText="ก่อนหน้า" nextText="ถัดไป" />
			<BackToAllCourse toAllCourseText="กลับเข้าสู่หน้าบทเรียนทั้งหมด" /> */}
		</div>
	);
}

function BreadcrumbNavigation(props: BreadcrumbNavigationProps): React.ReactElement<BreadcrumbNavigationProps> {
	const { text } = props;
	return (
		<div id="CourseNavigation__Breadcrumb">
			{text &&
				text.map((t, i) => {
					const isLast = i === text.length - 1;
					return (
						<>
							{/* eslint-disable-next-line */}
							<div className={isLast ? 'highlight' : 'main'}>
								{t}
							</div>
							{!isLast && <img alt=">" src={ArrowForward} className="breadcrumb-navigation-arrow" />}
						</>
					);
				})}
		</div>
	);
}

function BackToAllCourse(props: BackToAllCourseProps): React.ReactElement<BackToAllCourseProps> {
	const { toAllCourseText } = props;

	return (
		<div id="back-to-all-course">
			<img alt="<" src={ArrowBackward} className="icon--8" />
			<a href="https://google.com" className="margin--left-8">
				{toAllCourseText}
			</a>
		</div>
	);
}

function PrevNextLinks(props: PrevNextLinksProps): React.ReactElement<PrevNextLinksProps> {
	const { backText = 'ก่อนหน้า', nextText = 'ถัดไป' } = props;

	return (
		<div id="prev-next">
			<a href="https://google.com" className="course-navigation-text-highlight">
				{backText}
			</a>
			<img alt="|" src={VerticalBar} className="icon--8 margin--horizontal-8" />
			<a href="https://google.com" className="course-navigation-text-highlight">
				{nextText}
			</a>
		</div>
	);
}
