import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FooSliceState = number;

const initialState: FooSliceState = 0;

const fooSlice = createSlice({
	name: 'foo',
	initialState,
	reducers: {
		increment: (state, action: PayloadAction<number>) => state + action.payload,
		decrement: (state, action: PayloadAction<number>) => state - action.payload,
	},
});

export const { increment, decrement } = fooSlice.actions;

export default fooSlice.reducer;
