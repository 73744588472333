import React, { CSSProperties, ReactElement } from 'react';

import './Checkbox.scss';

interface CheckboxProps {
	id: string;
	name?: string;
	text?: string | ReactElement;
	checkboxStyle?: CSSProperties;
	inputStyle?: CSSProperties;
	labelStyle?: CSSProperties;
	checkboxClass?: string;
	onChecked?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Checkbox(props: CheckboxProps): ReactElement<CheckboxProps> {
	const { id, name, text, checkboxClass, checkboxStyle, inputStyle, labelStyle, onChecked } = props;

	return (
		<div style={checkboxStyle} id={id}>
			<input
				className="cl-checkbox"
				id={`${id}__input`}
				name={name}
				style={inputStyle}
				type="checkbox"
				onChange={onChecked}
			/>
			<label style={labelStyle} htmlFor={`${id}__input`} className={`cl-checkbox-label ${checkboxClass}`}>
				{text}
			</label>
		</div>
	);
}
