import axios from 'axios';
import { PER_PAGE } from '../constants/configs';
import { Topic } from '../models/Topic.d';
import { MAIN_ENDPOINT, TOPIC_ENDPOINT } from './urlService';

export async function getTopicsByCourseId(courseId: string | number, token: string, page: number = 1): Promise<Topic[]> {
	const url = `${MAIN_ENDPOINT}/${TOPIC_ENDPOINT.GET_TOPICS}?course=${courseId}&per_page=${PER_PAGE}&page=${page}`;

	const { data } = await axios.get<Topic[]>(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	let nextPageData: Topic[] = [];
	if (data.length === PER_PAGE) {
		try {
			nextPageData = await getTopicsByCourseId(courseId, token, page + 1);
		} catch {
			nextPageData = [];
		}
	}

	return [...data, ...nextPageData];
}
