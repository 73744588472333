import React, { useEffect, useState } from 'react';
import './LessonList.scss';
import TriangleDown from '../../../assets/images/icons/triangle-down.svg';
import TriangleLeft from '../../../assets/images/icons/triangle-left.svg';
import PlayCircle from '../../../assets/images/icons/play-circle.svg';
import PlayIcon from '../../../assets/images/icons/play.svg';
// eslint-disable-next-line import/no-cycle
import { MappedLesson } from '../../../views/Lesson/LessonView';
import { Lesson } from '../../../models/Lesson.d';
import { Topic } from '../../../models/Topic.d';

interface LessonListProps {
	courseId: number;
	lessons: MappedLesson[];
	selectedTopic?: number;
	onTopicClick: (lessonId: string | number, topicId: string | number) => void;
}

type CourseLengths = {
    [key: string]: number,
}

export default function LessonList(props: LessonListProps): React.ReactElement<LessonListProps> {
	const { lessons, onTopicClick, selectedTopic } = props;

	const courseLengths: CourseLengths = {
		'25901': 19,
	};

	const courseLength = courseLengths[`${props.courseId}`];

	const [expandingItems, setExpandingItem] = useState<number[]>([]);

	function toggle(id: number): void {
		// prettier-ignore
		if (!expandingItems.includes(id)) setExpandingItem([...expandingItems, id]);
		else setExpandingItem(expandingItems.filter(i => i !== id));
	}

	useEffect(() => {
		lessons.forEach(l => {
			const currentTopic = l.topics?.find(t => t.id === selectedTopic);
			if (currentTopic) setExpandingItem(_ => [..._, l.lesson!.id]);
		});
	}, [lessons, selectedTopic]);

	return (
		<div id="lesson-list">
			<div id="all-lesson">บทเรียนทั้งหมด</div>
			<div className="text--14 margin--vertical-16">
				{lessons.length} บท {lessons.reduce((p, c) => p + (c.topics?.length ?? 0), 0)} บทเรียน {courseLength && `(${courseLength} ชม.)`}
			</div>
			{lessons.map(l => (
				<LessonItem
					key={l.lesson?.id}
					isExpanding={expandingItems.includes(l.lesson!.id)}
					lesson={l.lesson!}
					toggle={toggle}
					topics={l.topics!}
					selectedTopic={props.selectedTopic}
					onTopicClick={onTopicClick}
				/>
			))}
		</div>
	);
}

interface LessonItemProps extends Pick<LessonListProps, 'onTopicClick'> {
	isExpanding: boolean;
	lesson: Lesson;
	topics: Topic[];
	selectedTopic?: number;
	toggle: (id: number) => void;
}

// prettier-ignore
function LessonItem(props: LessonItemProps): React.ReactElement<LessonItemProps> {
	const { isExpanding, lesson, topics, toggle, onTopicClick } = props;
	const { id, title } = lesson;

	// prettier-ignore
	const arrowClassName = `lessonItem__arrow--${isExpanding ? 'down' : 'left'}`;

	return (
		<div className="lessonItem" onClick={() => toggle(id)}>
			<div className="lessonItem__nameContainer">
				<div className="lessonItem__arrowContainer">
					<img
						className={arrowClassName}
						src={isExpanding ? TriangleDown : TriangleLeft}
						alt=""
					/>
				</div>
				{title.rendered}
			</div>
			{isExpanding
				&& topics?.map(t => <TopicItem lesson={lesson} onTopicClick={onTopicClick} key={t.id} isSelected={t.id === props?.selectedTopic} topic={t} />)}
		</div>
	);
}

interface TopicItemProps extends Pick<LessonListProps, 'onTopicClick'> {
	lesson: Lesson;
	isSelected: boolean;
	topic: Topic;
}

function TopicItem(props: TopicItemProps): React.ReactElement<TopicItemProps> {
	const { isSelected, topic, onTopicClick } = props;
	const className = `topicItem ${isSelected ? 'selected' : ''}`;

	return (
		<div className={className} onClick={onClick}>
			<img className="topicItem__icon" src={isSelected ? PlayIcon : PlayCircle} alt="Video" />
			<div className="topicItem__name">{topic.title.rendered}</div>
			{/* <div className="topicItem__duration">{topic.duration}</div> */}
		</div>
	);

	function onClick(e: React.MouseEvent): void {
		e.stopPropagation();
		onTopicClick(props.lesson.id, topic.id);
	}
}

// prettier-ignore
function SimpliflyingTitle (title: string) : string{
	let realTitle: string = "";
	let isColonFound: boolean = false;

	for (let i: number = 0; i < title.length; i += 1) {
		if (title[i] === ':') {
			isColonFound = true;
			i += 2;
		} 
		if (isColonFound) {
			realTitle += title[i];
		}
	}
	return realTitle;
}
