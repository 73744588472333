export interface EmbeddableLink {
	embeddable: boolean;
	href: string;
}

export interface Rendered {
	rendered: string;
}

export type Context = 'view' | 'embed' | 'edit';

export enum AuthorisationStatus {
	Loading,
	Authorised,
	Unauthorised,
	Error,
}
