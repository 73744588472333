import React, { ReactElement } from 'react';
import CourseLagoonLogo from '../../../assets/images/identity/logo.svg';
import CourseLagoonWorkmark from '../../../assets/images/identity/wordmark.svg';

import './Logo.scss';

type LogoProps = {
	height?: string;
	wordMarkHeight?: string;
	withWordmark?: boolean;
};

function renderWordMark(wordMarkHeight: string): ReactElement {
	return (
		<img
			alt=""
			id="courselagoon-logo_wordmark"
			src={CourseLagoonWorkmark}
			style={{ height: wordMarkHeight }}
			className="CourseLagoonLogoWordmark"
		/>
	);
}

function Logo(props: LogoProps): ReactElement<LogoProps> {
	// eslint-disable-next-line
	const {
		height = '44px',
		wordMarkHeight = height,
		withWordmark = false,
	} = props;

	return (
		<div className="CourseLagoonLogo" id="courselagoon-logo">
			<img
				alt="CourseLagoon"
				id="courselagoon-logo_image"
				src={CourseLagoonLogo}
				style={{ height }}
			/>
			{withWordmark && renderWordMark(wordMarkHeight)}
		</div>
	);
}

export default Logo;
