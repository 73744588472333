/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppSliceState = {
	loadingScreenCount: number;
};

const initialState: AppSliceState = {
	loadingScreenCount: 0,
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		load: state => ({ ...state, loadingScreenCount: state.loadingScreenCount + 1 }),
		unload: state => ({ ...state, loadingScreenCount: state.loadingScreenCount - 1 }),
		loadBy: (state, action: PayloadAction<number>) => ({
			...state,
			loadingScreenCount: state.loadingScreenCount + action.payload,
		}),
		unloadBy: (state, action: PayloadAction<number>) => ({
			...state,
			loadingScreenCount: state.loadingScreenCount - action.payload,
		}),
	},
});

export const { load, loadBy, unload, unloadBy } = appSlice.actions;

export default appSlice.reducer;
