import axios from 'axios';
import { MAIN_ENDPOINT, USER_ENDPOINT } from './urlService';

export interface CourseInfo {}

export async function getUsers(): Promise<any> {
	const url = `${MAIN_ENDPOINT}/${USER_ENDPOINT.GET_USERS}`;
	const { data } = await axios.get<any>(url);

	return data;
}

export async function getMe(token: string): Promise<any> {
	const url = `${MAIN_ENDPOINT}/${USER_ENDPOINT.GET_USERS}/me`;
	const { data } = await axios.get<any>(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return data;
}
