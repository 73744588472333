import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import Logo from '../Logo/Logo';
import Button from '../Button/Button';

import { Paths } from '../../../router/routes';
import './NavBar.scss';
import { RootState } from '../../../store';
import { setUserInfo, setUserState } from '../../../store/authSlice';
import { USERINFO } from '../../../constants/localStorage';
import ProfilePicture from '../../../assets/images/icons/placeholders/profile-picture.svg';
import { setMe } from '../../../store/userSlice';

function NavBar(): React.ReactElement {
	const dispatch = useDispatch();
	const userInfo = useSelector((state: RootState) => state.user)?.me;
	const userState = useSelector((state: RootState) => state.auth)?.userState;
	const [isShowingHamburgerMenu, setIsShowingHamburgerMenu] = useState(false);

	const isLoggedIn = userState === 'loggedIn';

	function onLogButtonClick(): void {
		if (isLoggedIn) {
			localStorage.removeItem(USERINFO);
			dispatch(setUserState(undefined));
			dispatch(setUserInfo(undefined));
		}
	}

	function onHamburgerMenuClick(): void {
		setIsShowingHamburgerMenu(!isShowingHamburgerMenu);
	}

	const SideMenu = (): React.ReactElement => {
		return (
			<div className="NavigationSidemenu" onClick={onHamburgerMenuClick}>
				<div className="NavigationSidemenu__LinkContainer" onClick={e => e.stopPropagation()}>
					<div className="NavigationSidemenu__Profile">
						<img className="NavigationSidemenu__ProfilePicture" src={ProfilePicture} alt="" />
						<div className="NavigationSidemenu__ProfileName">{userInfo?.name}</div>
					</div>

					<ul>
						<li>
							<Link to={Paths.Homepage} onClick={onHamburgerMenuClick}>
								หน้าหลัก
							</Link>
						</li>
						{userState === 'loggedIn' && (
							<li>
								<Link to={Paths.MyCourses} onClick={onHamburgerMenuClick}>
									คอร์สของฉัน
								</Link>
							</li>
						)}
						<li>
							<Link to={Paths.Courses} onClick={onHamburgerMenuClick}>
								คอร์สทั้งหมด
							</Link>
						</li>
						<li>
							<Link to={Paths.Contact} onClick={onHamburgerMenuClick}>
								ติดต่อเรา
							</Link>
						</li>
					</ul>
				</div>
			</div>
		);
	};

	return (
		<>
			<nav className="NavigationBar Desktop">
				<div className="NavigationBar__Left">
					{/* <HamburgerMenu /> */}
					<Link to={Paths.Homepage}>
						<div className="NavigationBar__Logo">
							{/* <div className="NavigationBar__LogoBadge">Soft Opening</div> */}
							<Logo withWordmark />
						</div>
					</Link>
					<div className="NavigationBar__Menu">
						<Link to={Paths.Homepage}>หน้าหลัก</Link>
						<Link to={Paths.Courses}>คอร์สทั้งหมด</Link>
					</div>
				</div>
				<div className="NavigationBar__Right">
					{isLoggedIn && <Link to={Paths.MyCourses}>คอร์สของฉัน</Link>}
					<Link to={Paths.Contact}>ติดต่อเรา</Link>
					<Link to={!isLoggedIn ? Paths.Login : Paths.Homepage} className="link">
						<Button onClick={onLogButtonClick} buttonText={!isLoggedIn ? 'เข้าสู่ระบบ' : 'ออกจากระบบ'} />
					</Link>
				</div>
			</nav>
			<nav className="NavigationBar Mobile">
				<HamburgerMenu onClick={onHamburgerMenuClick} />
				<Link to={Paths.Homepage}>
					<Logo withWordmark />
				</Link>
				<Link to={!isLoggedIn ? Paths.Login : Paths.Homepage} className="link">
					<Button onClick={onLogButtonClick} buttonText={!isLoggedIn ? 'เข้าสู่ระบบ' : 'ออกจากระบบ'} />
				</Link>
			</nav>
			{isShowingHamburgerMenu && <SideMenu />}
		</>
	);
}

export default NavBar;
