import React from 'react';
import './ToCView.scss';

const ToCView: React.FC = () => {
	return (
		<div id="ToCView">
			<h1>ข้อกำหนดการใช้งาน</h1>
			<p>
				เกี่ยวกับบัญชีของผู้ใช้: เมื่อคุณลงทะเบียนสำหรับบัญชี Courselagoon
				คุณจะต้องให้ข้อมูลที่ครบถ้วนและถูกต้องเกี่ยวกับชื่อ นามสกุลและอีเมลของคุณ
				เพราะข้อมูลเหล่านี้จำเป็นสำหรับสนับสนุนผู้ใช้งานขณะที่คุณใช้บริการที่ Courselagoon
				ดังนั้นทางเราจะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายใดๆ
				ถ้าหากข้อมูลที่คุณให้ทางเราไม่เป็นความจริงหรือไม่ครบถ้วน
				ข้อมูลที่คุณได้ให้ระหว่างขั้นตอนการลงทะเบียนนั้นจะช่วยให้เราสามารถนำเสนอเนื้อหาและบริการให้กับคุณได้อย่างถูกต้อง
			</p>
			<p>
				รหัสผ่านของบัญชี: คุณมีหน้าที่รับผิดชอบในการรักษาชื่อบัญชีผู้ใช้งานและรหัสผ่านไว้เป็นความลับ
				ทางเราจะไม่รับผิดชอบต่อความเสียหายใด ๆ เนื่องจากการถูกขโมยรหัสผ่าน
				เราจะไม่รับผิดชอบต่อผลกำไรหรือขาดทุนอันเกิดจากโอกาสทางธุรกิจที่เกิดจากการ ใช้ หรือใช้เว็บไซต์
				หรือข้อมูลในเว็บไซต์นี้ในทางที่ผิด
			</p>
			<p>
				คุณต้องไม่ใช้โปรแกรม เครื่องมือหรือวิธีการออกแบบใดๆ ที่มารบกวน, ทำลาย หรือ จำกัดการเรียนการสอนของ
				Courselagoon ซึ่งเป็นลิขสิทธิ์ร่วมของผ้สอนกับทาง Courselagoon หากคุณละเมิดข้อตกลงในการใช้งานเหล่านี้
				ทางเราจะยกเลิกบัญชีผู้ใช้งาน ของคุณและคุณต้องชดใช้ค่าเสียหายตามข้อบังคับทางกฎหมาย
			</p>
			<p>
				คุณต้องไม่สนับสนุนหรืออำนวยความสะดวกให้กับกิจกรรมใด ๆ ที่ละเมิดต่อข้อกำหนดการใช้งาน
				ที่จะแทรกแทรงการทำลายข้อมูลของการสอนในระบบ Courselagoon
			</p>
			<p>
				คุณต้องไม่แบ่งสิทธิการควบคุมบัญชีการใช้งานของคุณให้กับผู้อื่น
				ถ้ามีการแบ่งสิทธิการควบคุมบัญชีการใช้งานร่วมกัน ทางเราจะลบบัญชีนั้นออกจากระบบทันที คุณต้องไม่โยกย้าย,
				เปลี่ยนแปลง, ปกปิดลิขสิทธิ์, เครื่องหมายทางการค้า หรือ เครื่องหมายบริการใด ๆ ที่มาพร้อมกับเนื้อหาของ
				Courselagoon และคุณต้องไม่ทำซ้ำ, แก้ไข หรือ ดัดแปลง เพื่อที่จะเตรียมดำเนินการแสดง เผยแพร่ แจกจ่าย ส่งต่อ
				ออกอากาศ ขายหรือใช้ประโยชน์จากเนื้อหาของ Courselagoon หากคุณละเมิดข้อตกลงในการใช้งานเหล่านี้ ทาง
				Courselagoon จะยกเลิกบัญชีผู้ใช้งานของคุณกับทาง Courselagoon
				และคุณต้องชดใช้ค่าเสียหายตามข้อบังคับทางกฎหมายทรัพย์สินทางปัญญา
			</p>
			<p>
				คุณต้องไม่ใช้เว็บไซต์นี้ในทางที่ก่อให้เกิด หรืออาจก่อให้เกิดความเสียหายต่อเว็บไซต์
				หรือเกิดความขัดข้องในการเข้าถึงเว็บไซต์ดังกล่าว หรือใช้ในทางที่ผิดกฎหมาย, หลอกลวง, เป็นอันตราย หรือ
				เป็นการเชื่อมต่อกับสิ่งที่ผิดกฎหมาย หรือกิจกรรมใดๆ ก็ตามที่หลอกลวงและมีจุดประสงค์ที่เป็นอันตราย
				ถ้าหากคุณละเมิดข้อตกลงในการใช้งานเหล่านี้ทาง Courselagoon จะยกเลิกบัญชีผู้ใช้งานของคุณกับทาง
				Courselagoon และคุณต้องชดใช้ค่าเสียหายตามหลักกฎหมาย
			</p>
			<p>
				ในระหว่างการพูดคุย สื่อสารในระบบ Courselagoon ควรใช้คำพูดที่สุภาพ นุ่มนวล ไพเราะ ไม่หยาบคาย
				เป็นคำพูดในเชิงบวก ไม่พูดติเตียนให้ร้ายผู้อื่น (รวมถึง ไม่มีข้อจำกัดต่อเชื้อชาติ, ศาสนา, เพศ, อายุ,
				รสนิยมทางเพศ หรือความพิการทางร่างกาย หรือจิตใจ)
			</p>
			<p>
				คุณต้องไม่ลอกเลียนแบบบุคคลใดๆ หรือพยายามทำให้บุคคลอื่นเข้าใจว่าคุณเป็นคนในระบบของ Courselagoon
				หากคุณละเมิดข้อตกลงในการใช้งานเหล่านี้ทาง Courselagoon จะยกเลิกบัญชีผู้ใช้งานของคุณกับทาง Courselagoon
			</p>
			<p>
				หากคุณละเมิดกฎใด ๆ เหล่านี้ ทางเราจะระงับหรือยุติบัญชีของคุณ เราขอสงวนสิทธิ์ในการใช้ข้อมูลใด ๆ
				ที่คุณได้ให้ไว้กับเราและให้อำนาจแก่เจ้าพนักงานในการตรวจสอบและดำเนินการทางกฎหมายตามความเหมาะสม
			</p>
			<p>
				Courselagoon จะไม่รับผิดชอบต่อความล่าช้าหรือความล้มเหลวใด ๆ ที่เกิดจากเหตุสุดวิสัย เช่น
				การหยุดชะงักของการขนส่ง, การเชื่อมต่อไฟฟ้าขัดข้อง, อุปกรณ์ชำรุด
				หรือภัยพิบัติทางธรรมชาติที่ส่งผลกระทบต่อประสิทธิภาพการทำงาน
			</p>
			<p>
				คุณต้องไม่ส่งเสริมและเผยแพร่พฤติกรรมที่บิดเบือน
				ซึ่งขัดต่ออำนาจความคิดเห็นทางการเมืองและนโยบายของรัฐบาลภายใต้สถานการณ์ใดๆ ก็ตาม
				หากคุณละเมิดกฎเหล่านี้ทางเราจะไม่เพียงแต่ยกเลิกบัญชีการใช้งานของคุณ
				แต่เราจะส่งข้อมูลทั้งหมดของคุณที่ได้ให้ไว้กับเราต่อเจ้าพนักงานเพื่อตรวจสอบและดำเนินการทางกฎหมาย
				ข้อมูลของคุณทั้งหมดจะถูกเก็บไว้เป็นความลับ ทางเราจะไม่มีการขายหรือแลกเปลี่ยนข้อมูลของคุณกับบุคคลที่สาม
			</p>
			<p>
				Courselagoon ขอสงวนสิทธิ์ในการลบ แก้ไข และเปลี่ยนแปลงข้อมูลในบัญชีของคุณ ในกรณีที่คุณละเมิดกฎใด ๆ
				ดังกล่าวข้างต้น โดยไม่จำเป็นต้องแจ้งให้คุณยินยอมก่อน
			</p>
			<p>
				Courselagoon ขอสงวนสิทธิ์ในการเปลี่ยนแปลงและปรับปรุงข้อกำหนดการใช้งานเหล่านี้
				เพื่อชี้แจงแนวทางปฏิบัติของบริษัทที่เกี่ยวกับแนวทางแบบใหม่หรือระเบียบปฏิบัติที่แตกต่างไปจากเดิม
				และทางเราจะแจ้งให้คุณทราบ ผ่านบนเว็บไซต์หรือช่องทางอื่น ๆ ของ Courselagoon อย่างเป็นทางการต่อไป
			</p>
		</div>
	);
};

export default ToCView;
