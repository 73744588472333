import { AxiosError } from 'axios';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Paths } from '../../../router/routes';
import { wordpressLogin } from '../../../store/authSlice';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import Loading from '../Loading/Loading';
import Logo from '../Logo/Logo';

import './Login.scss';

export default function Login(): ReactElement {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [errorText, setErrorText] = useState('');

	const dispatch = useDispatch();

	return (
		<div id="login-component">
			<Logo withWordmark height="72px" wordMarkHeight="56px" />
			<h1 id="login-component__title" className="text--18 text--semi-bold">
				เข้าสู่ระบบ CourseLagoon
			</h1>

			<HorizontalLine width="144px" />

			<div className="margin--top-16" style={{ width: '100%' }}>
				<input
					className="icon icon--email"
					type="text"
					placeholder="อีเมล"
					value={username}
					onChange={e => setUsername((e.target as HTMLInputElement).value)}
				/>
				<input
					className="icon icon--unlocked"
					type="password"
					placeholder="รหัสผ่าน"
					value={password}
					onChange={e => setPassword((e.target as HTMLInputElement).value)}
				/>

				<a
					href="#"
					onClick={() =>
						setErrorText(
							'ขออภัย ขณะนี้ระบบมีปัญหา โปรดส่งอีเมลมายัง courselagoon@gmail.com โดยใช้อีเมลที่สมัครไว้ เราจะส่งลิงก์รีเซ็ทรหัสผ่านให้ทางนั้น'
						)
					}
					className="link"
					id="login-component__forgotten-password"
				>
					ลืมรหัสผ่าน?
				</a>

				{errorText && <div className="errorText">{errorText}</div>}

				{isLoading ? (
					<div className="flex flex--justify-center">
						<Loading />
					</div>
				) : (
					<button type="button" onClick={login}>
						เข้าสู่ระบบ
					</button>
				)}

				<div id="login-component__sign-up" className="margin--top-16 text--14">
					ยังไม่ได้เป็นสมาชิก? &nbsp;
					<Link to={Paths.Register} className="link">
						คลิกเพื่อสมัคร
					</Link>
				</div>
			</div>
		</div>
	);

	function login(): void {
		if (errorText) setErrorText('');

		if (!username && !password) setErrorText('โปรดใส่ชื่อผู้ใช้หรืออีเมล และรหัสผ่าน');
		else if (!username) setErrorText('โปรดใส่ชื่อผู้ใช้หรืออีเมล');
		else if (!password) setErrorText('โปรดใส่รหัสผ่าน');

		if (!username || !password) return;

		setIsLoading(true);
		dispatch(wordpressLogin(username, password, onError));

		function onError(err: AxiosError) {
			setIsLoading(false);
			if (err.response?.status === 403) setErrorText('ขออภัย ชื่อผู้ใช้หรือรหัสผ่านของคุณไม่ถูกต้อง');
			else setErrorText(`พบปัญหาที่ไม่ทราบ: ${JSON.stringify(err.response?.data)}`);
		}
	}
}
