import React from 'react';

import './GenericContainerView.scss';

interface Props {
	isNarrow?: boolean;
}

const GenericContainer: React.FC<Props> = (props) => {
	return (
		<div className={`GenericContainer ${props.isNarrow ? 'Narrow' : ''}`}>
			{props.children}
		</div>
	);
}

export default GenericContainer;
