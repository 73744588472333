/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, matchPath } from 'react-router-dom';
import Button from '../../components/common/Button/Button';
import LessonBox from '../../components/content/LessonBox/LessonBox';
import { CourseOverview } from '../../models/CourseOverview.d';
import { Paths } from '../../router/routes';
import { getCourseOverviewByCourseId } from '../../services/courseService';
import { RootState } from '../../store';
import LoadingView from '../LoadingView/LoadingView';

import './Course.scss';

function Course(): React.ReactElement {
	const currentUser = useSelector((state: RootState) => state.user)?.me;

	const [courseOverview, setCourseOverview] = useState<CourseOverview>();

	// eslint-disable-next-line no-restricted-globals
	const path = matchPath(location.pathname, {
		path: Paths.Course,
		exact: true,
	});
	const courseId = (path?.params as any)?.courseId;

	useEffect(() => {
		if (!courseId) return;
		getCourseOverviewByCourseId(courseId, currentUser?.id)
			.then(setCourseOverview)
			.catch(() => {});
	}, [courseId, currentUser]);

	const lessonPath = generatePath(Paths.Lesson, {
		courseId,
	});

	if (!courseOverview) return <LoadingView />;

	const buttonLink = courseOverview?.is_enrolled ? lessonPath : Paths.How2Purchase;

	const BuyStartButton = () => (
		<div id="course-purchase">
			<Link to={buttonLink} className="link">
				<Button buttonText={courseOverview?.is_enrolled ? 'เริ่มเรียน' : 'ซื้อคอร์ส'} classes="filled blue" />
			</Link>
		</div>
	);

	return (
		<div id="course">
			<div
				id="course-info"
				dangerouslySetInnerHTML={{
					__html: courseOverview?.post_content ?? '',
				}}
			/>

			<BuyStartButton />

			<h2>บทเรียนทั้งหมด</h2>

			<div id="course-lesson-box-list">
				{courseOverview?.lessons.map((l, i) => (
					<LessonBox key={l.id} courseId={courseId} lesson={l} index={i + 1} />
				))}
			</div>

			<BuyStartButton />
		</div>
	);
}

export default Course;
