import React from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../components/common/Button/Button';
import Logo from '../../components/common/Logo/Logo';
import { Paths } from '../../router/routes';
// import { RootState } from '../../store';

import './HomepageView.scss';

function HomepageView(): React.ReactElement {
	// const userState = useSelector((state: RootState) => state.auth)?.userState;

	// const isLoggedIn = userState === 'loggedIn'
	// const primaryPath = isLoggedIn ? Paths.MyCourses : Paths.Register;
	// const primaryCta = isLoggedIn ? 'คอร์สของฉัน' : 'สมัครสมาชิก'

	return (
		<div id="HomepageView">
			<div id="HomepageView__TitleContainer">
				<h1>ยินดีต้อนรับสู่</h1>
				<Logo height="12rem" withWordmark />
				<h2>เว็บไซต์การศึกษาจากผู้สอนคุณภาพ</h2>
				<div id="HomepageView__ButtonContainer">
					{/* <Link to={primaryPath}>
						<Button buttonText={primaryCta} classes="filled yellow" />
					</Link> */}
					<Link to={Paths.Courses}>
						<Button buttonText="ดูคอร์สทั้งหมด" classes="filled blue HomepageView__Button" />
					</Link>
				</div>
			</div>
		</div>
	);
}

export default HomepageView;
