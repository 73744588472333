// export const CORS_ENDPOINT = 'https://ancient-waterfall-b294.thammarith.workers.dev/cors/?apiurl=';
export const ROOT_ENDPOINT = 'https://wp.courselagoon.com';
export const MAIN_ENDPOINT = `${ROOT_ENDPOINT}`;
export const WP_JSON = 'wp-json';

export const AUTH_ENDPOINT = {
	GET_TOKEN: `${WP_JSON}/jwt-auth/v1/token`,
	POST_VALIDATE: `${WP_JSON}/jwt-auth/v1/token/validate`,
};

export const COURSE_ENDPOINT = {
	GET_COURSES: `${WP_JSON}/ldlms/v2/sfwd-courses`,
	GET_COURSE_OVERVIEW: (courseId: string | number, userId?: string | number) =>
		`${WP_JSON}/courselagoon/v1/courses/${courseId}/overview${userId ? `?user_id=${userId}` : ''}`,
	GET_COURSE_STEP_PROGRESS: (userId: string | number, courseId: string | number) =>
		`${WP_JSON}/ldlms/v2/users/${userId}/course-progress/${courseId}/steps`,
	GET_USER_COURSES: (userId: string | number) => `${WP_JSON}/ldlms/v2/users/${userId}/courses`,
};

export const LESSON_ENDPOINT = {
	GET_LESSONS: `${WP_JSON}/ldlms/v2/sfwd-lessons?course=`,
};

export const PAGE_ENDPOINT = {
	GET_PAGE: `${WP_JSON}/wp/v2/pages`,
};

export const TOPIC_ENDPOINT = {
	GET_TOPICS: `${WP_JSON}/ldlms/v2/sfwd-topic`,
};

export const USER_ENDPOINT = {
	GET_USERS: `${WP_JSON}/wp/v2/users`,
	CL_USERS: `${WP_JSON}/courselagoon/v1/users`,
};
