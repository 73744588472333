import React from 'react';

import './Button.scss';

type ButtonProps = {
	buttonText: string;
	buttonType?: 'button' | 'reset' | 'submit' | undefined;
	classes?: string;
	onClick?: () => void;
};

function Button(props: ButtonProps): React.ReactElement<ButtonProps> {
	const { classes, buttonText, buttonType = 'button', onClick } = props;

	return (
		// eslint-disable-next-line react/button-has-type
		<button onClick={onClick} className={`button ${classes}`} type={buttonType}>
			{buttonText}
		</button>
	);
}

export default Button;
