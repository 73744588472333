/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, matchPath, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import BreadcrumbNavigation from '../../components/common/BreadcrumbNavigation/BreadcrumbNavigation';
// eslint-disable-next-line import/no-cycle
import LessonList from '../../components/content/LessonList/LessonList';
import { CourseStep } from '../../models/CourseStep.d';
import { Lesson } from '../../models/Lesson.d';
import { Topic } from '../../models/Topic.d';
import { AuthorisationStatus } from '../../models/Common.d';
import { Paths } from '../../router/routes';
import { getCourseStepByCourseId } from '../../services/courseService';
import { getLessonsFromCourse } from '../../services/lessonService';
import { getTopicsByCourseId } from '../../services/topicService';
import { RootState } from '../../store';

import HamburgerExpand from '../../assets/images/icons/hamburger-expand.svg';
import UnauthorisedPlaceholder from '../../assets/images/placeholders/unauthorised.png';

import './LessonView.scss';
import LoadingView from '../LoadingView/LoadingView';

export interface MappedLesson {
	lesson?: Lesson;
	topics?: Topic[];
}

function mapLesson(courseStep: CourseStep, lessons: any[], topics: any[]): MappedLesson[] {
	const data = courseStep.l.map(i => i.split(':'));
	const mappedLessons: MappedLesson[] = [];
	let lessonObject: MappedLesson = {};
	let currentLesson;

	// eslint-disable-next-line
	for (const [key, id] of data) {
		if (key === 'sfwd-lessons') {
			// eslint-disable-next-line
			if (currentLesson) mappedLessons.push(lessonObject);
			const lesson = lessons.find(l => `${l.id}` === `${id}`);
			currentLesson = id;
			lessonObject = {
				lesson,
				topics: [],
			};
		}
		if (key === 'sfwd-topic') {
			const topic = topics.find(t => `${t.id}` === `${id}`);
			// eslint-disable-next-line
			lessonObject?.topics?.push(topic);
		}
	}

	mappedLessons.push(lessonObject);
	return mappedLessons;
}

function LessonView(): React.ReactElement {
	const path = matchPath(location.pathname, {
		path: location.pathname.includes('topics') ? Paths.Topic : Paths.Lesson,
		exact: true,
	});

	const [lessons, setLessons] = useState<Lesson[]>();
	const [topics, setTopics] = useState<Topic[]>();
	const [steps, setSteps] = useState<CourseStep>();
	const [mappedLessons, setMappedLessons] = useState<MappedLesson[]>();

	const [isAuthorised, setIsAuthorised] = useState<boolean[]>([]);

	const [isShowingSidebar, setIsShowingSidebar] = useState(true);

	const { courseId } = path?.params as any;
	const { lessonId } = path?.params as any;
	const { topicId } = path?.params as any;
	const history = useHistory();

	const token = useSelector((state: RootState) => state.auth)?.userInfo?.token;
	const course = useSelector((state: RootState) => state.course)?.courses.find(c => `${c.id}` === courseId);

	useEffect(() => {
		if (!courseId || !token) return;

		const handleError = ({ response }: AxiosError): void => {
			if (response?.status === 403) setIsAuthorised(_ => [..._, false]);
			const toPath = generatePath(Paths.Course, {
				courseId,
			});
			history.push(toPath);
		};

		getTopicsByCourseId(courseId!, token!)
			.then(setTopics)
			.then(() => setIsAuthorised(_ => [..._, true]))
			.catch(handleError);

		getCourseStepByCourseId(courseId!, token!)
			.then(({ data }) => setSteps(data))
			.then(() => setIsAuthorised(_ => [..._, true]))
			.catch(handleError);

		getLessonsFromCourse(courseId!, token!)
			.then(setLessons)
			.then(() => setIsAuthorised(_ => [..._, true]))
			.catch(handleError);
	}, [token, courseId]);

	useEffect(() => {
		if (!steps || !lessons || !topics) return;
		const mapped = mapLesson(steps, lessons, topics);
		setMappedLessons(mapped);
	}, [steps, lessons, topics]);

	const content = topics?.find(t => `${t.id}` === topicId) ?? topics?.[0];
	const lesson =
		mappedLessons?.find(l => `${l.lesson?.id}` === `${lessonId}`) ??
		mappedLessons?.find(l => l.topics?.find(t => t.id === content?.id));

	const getAuthorisationStatus = (): AuthorisationStatus => {
		if (!isAuthorised.length) return AuthorisationStatus.Loading;
		return isAuthorised.reduce((p, c) => p && c)
			? AuthorisationStatus.Authorised
			: AuthorisationStatus.Unauthorised;
	};

	if (getAuthorisationStatus() === AuthorisationStatus.Loading) return <LoadingView />;

	if (getAuthorisationStatus() === AuthorisationStatus.Unauthorised)
		return (
			<div className="UnauthorisedPlaceholder">
				<div className="UnauthorisedPlaceholder__Overlay">ขออภัย คุณยังไม่ได้ซื้อคอร์สนี้</div>
				<img src={UnauthorisedPlaceholder} alt="Unauthorised" />
			</div>
		);

	return (
		<div id="LessonView">
			<div id="LessonView__Breadcrumb">
				{course && (
					<BreadcrumbNavigation
						text={[course.title.rendered, lesson?.lesson?.title.rendered, content?.title.rendered]}
					/>
				)}
			</div>
			<div id="LessonView__Content" className={isShowingSidebar ? '' : 'longer'}>
				{content && (
					<>
						<h1>{content.title.rendered}</h1>
						<div dangerouslySetInnerHTML={{ __html: content.content.rendered }} />
					</>
				)}
			</div>
			<div id="LessonView__Sidebar" className={isShowingSidebar ? '' : 'hidden'}>
				<div id="LessonView__SidebarToggleButton" onClick={toggleSidebar}>
					<img src={HamburgerExpand} alt="Show menu" />
				</div>
				<div id="LessonView__SidebarContentContainer">
					{course && mappedLessons && (
						<LessonList
							courseId={courseId}
							selectedTopic={content?.id}
							lessons={mappedLessons!}
							onTopicClick={onTopicClick}
						/>
					)}
				</div>
			</div>
		</div>
	);

	function toggleSidebar(): void {
		setIsShowingSidebar(!isShowingSidebar);
	}

	function onTopicClick(_lessonId: string | number, _topicId: string | number): void {
		const toPath = generatePath(Paths.Topic, {
			courseId,
			lessonId: _lessonId,
			topicId: _topicId,
		});
		window.scrollTo(0, 0);
		history.push(toPath);
	}
}

export default LessonView;
