/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { USERINFO } from '../constants/localStorage';

import { AppThunk } from '../reducers/rootReducer';
import { login, UserInfo, validateToken } from '../services/authService';

type AuthSliceState = {
	userState: 'loggedIn' | 'unauthorised' | 'error' | undefined;
	userInfo?: UserInfo;
};

const initialState: AuthSliceState = {
	userState: undefined,
	userInfo: undefined,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUserState(state, action: PayloadAction<AuthSliceState['userState']>) {
			const { payload: userState } = action;

			return {
				...state,
				userState,
			};
		},
		setUserInfo(state, action: PayloadAction<UserInfo | undefined>) {
			const { payload: userInfo } = action;
			return {
				...state,
				userInfo,
			};
		},
	},
});

export const { setUserState, setUserInfo } = authSlice.actions;

export const checkToken =
	(_token?: string): AppThunk =>
	async dispatch => {
		const userInfo = JSON.parse(localStorage.getItem(USERINFO)!) as UserInfo | null;
		const token = _token ?? userInfo?.token;
		try {
			const response = await validateToken(token);

			if (response == null) dispatch(setUserState('error'));
			else if (response.data.status === 200) dispatch(setUserState('loggedIn'));
			else dispatch(setUserState('unauthorised'));

			if (response?.data.status !== 200) dispatch(setUserInfo(undefined));
		} catch {
			dispatch(setUserState('error'));
		}
	};

export const wordpressLogin =
	(username: string, password: string, onError?: (err: AxiosError) => void): AppThunk =>
	async dispatch => {
		try {
			const userInfo = await login(username, password);
			localStorage.setItem(USERINFO, JSON.stringify(userInfo));
			dispatch(setUserState('loggedIn'));
			dispatch(setUserInfo(userInfo));
		} catch (err) {
			if (onError) onError(err);
			// dispatch(getIssuesFailure(err.toString()));
		}
	};

export default authSlice.reducer;
