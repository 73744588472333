import React, { useEffect } from 'react';
import { initialLoad } from '../../services/bootstrapService';

function Fallback(): React.ReactElement {
	// useEffect(() => {
	// 	initialLoad();
	// }, []);

	const foo = () => {
		// console.log(555);
		// initialLoad();
	}

	return <div onClick={foo}><h1>404</h1></div>
	// return <iframe src="https://cl.tmr.in.th/contact/" title="test" />;
}

export default Fallback;
