import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import HamburgerIcon from '../../../../assets/images/icons/hamburger.svg';

import './HamburgerMenu.scss';

type Props = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

function HamburgerMenu(props: Props): React.ReactElement {
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <img {...props} id="hamburger-menu-icon" src={HamburgerIcon} alt="menu" />;
}

export default HamburgerMenu;
