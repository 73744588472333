export enum Paths {
	Contact = '/contact',
	Course = '/courses/:courseId',
	Courses = '/courses',
	Lesson = '/courses/:courseId/lessons',
	Topic = '/courses/:courseId/lessons/:lessonId/topics/:topicId',
	MyCourses = '/my/courses',
	How2Purchase = '/how-to/purchase',
	Login = '/login',
	Quiz = '/quiz',
	Reading = '/reading',
	Register = '/register',
	ToC = '/terms-and-conditions',
	Test = '/test',
	Homepage = '/',
	Fallback = '*',
}
