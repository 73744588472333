import React, { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Lesson } from '../../../models/CourseOverview.d';

import ArrowForward from '../../../assets/images/icons/arrow-forward.svg';
import PlayCircle from '../../../assets/images/icons/play-circle.svg';

import './LessonBox.scss';
import { Paths } from '../../../router/routes';

interface LessonBoxProps {
	courseId: number;
	lesson: Lesson;
	index: number;
}

function LessonBox({ courseId, lesson, index }: LessonBoxProps): React.ReactElement<LessonBoxProps> {
	const [isShowingContent, setIsShowingContent] = useState(false);

	function toggleContent(): void {
		setIsShowingContent(!isShowingContent);
	}

	return (
		<div className="LessonBox">
			<div className="LessonBoxHeader" onClick={toggleContent}>
				<div className={`LessonBoxHeader__Arrow ${isShowingContent ? 'up' : 'down'}`}>
					<img src={ArrowForward} alt=">" />
				</div>
				<div className="LessonBoxHeader__Chapter">
					<div className="LessonBoxHeader__ChapterNumberText">บทที่</div>
					<div className="LessonBoxHeader__ChapterNumber">{index}</div>
				</div>
				<div className="LessonBoxHeader__Title">
					<div className="LessonBoxHeader__TitleText">{lesson.post_title}</div>
					<div className="LessonBoxHeader__TitleInfo">{lesson.topics.length} บทเรียน</div>
				</div>
			</div>
			{isShowingContent && (
				<div className="LessonBoxContent">
					{lesson.topics.map(t => (
						<Link
							key={`${courseId}-${lesson.id}-${t.id}`}
							to={() => generatePath(Paths.Topic, { courseId, lessonId: lesson.id, topicId: t.id })}
						>
							<div className="LessonBoxContent__Topic">
								<img className="LessonBoxContent__TopicPlayIcon" src={PlayCircle} alt="▶️" />
								<div className="LessonBoxContent__TopicTitle">{t.post_title}</div>
							</div>
						</Link>
					))}
				</div>
			)}
		</div>
	);
}

export default LessonBox;
