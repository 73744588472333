import axios from 'axios';
import { Lesson } from '../models/Lesson.d';
import { LESSON_ENDPOINT, MAIN_ENDPOINT } from './urlService';

export async function getLessonsFromCourse(courseId: number | string, token: string): Promise<Lesson[]> {
	const url = `${MAIN_ENDPOINT}/${LESSON_ENDPOINT.GET_LESSONS}${courseId}`;
	const { data } = await axios.get<Lesson[]>(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	return data;
}
