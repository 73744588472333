/* eslint-disable import/no-cycle */
import { combineReducers, Action } from '@reduxjs/toolkit';

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';
import appSlice from '../store/appSlice';
import authSlice from '../store/authSlice';
import courseSlice from '../store/courseSlice';

import fooSlice from '../store/fooSlice';
import todosSlice from '../store/todosSlice';
import userSlice from '../store/userSlice';

const rootReducer = combineReducers({
	foo: fooSlice,
	app: appSlice,
	auth: authSlice,
	todos: todosSlice,
	course: courseSlice,
	user: userSlice,
});

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default rootReducer;
