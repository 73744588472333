import React, { ReactElement } from 'react';

import './HorizontalLine.scss';

type Props = {
	borderColour?: string;
	borderStyle?: string;
	borderWidth?: string;
	width?: string;
};

export default function HorizontalLine(props: Props): ReactElement<Props> {
	const {
		borderColour: borderColor,
		borderStyle = 'solid',
		borderWidth = '1px',
		width = '100%',
	} = props;

	return (
		<hr className="horizontal-line" style={{ borderColor, borderStyle, borderWidth, width }} />
	);
}
