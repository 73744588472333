/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { UserRegistration } from '../models/User.d';
import { AUTH_ENDPOINT, MAIN_ENDPOINT, USER_ENDPOINT } from './urlService';

export interface UserInfo {
	token: string;
	user_email: string;
	user_nicename: string;
	user_display_name: string;
}

export interface ValidateTokenResponse {
	code: string;
	message?: string;
	data: {
		status: number;
	};
}

export async function validateToken(token?: string): Promise<ValidateTokenResponse | null> {
	if (!token) {
		return {
			code: 'INTERNAL_MISSING_TOKEN',
			data: {
				status: 400,
			},
		};
	}

	const url = `${MAIN_ENDPOINT}/${AUTH_ENDPOINT.POST_VALIDATE}`;
	try {
		const { data } = await axios.post<ValidateTokenResponse>(
			url,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return data;
	} catch (err) {
		return null;
	}
}

export async function register(userRegistration: UserRegistration): Promise<any> {
	const url = `${MAIN_ENDPOINT}/${USER_ENDPOINT.CL_USERS}/register`;
	const formData = new FormData();
	Object.entries(userRegistration).forEach(([k, v]) => formData.append(k, v));
	return axios({
		method: 'POST',
		url: url,
		data: formData,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
}

export async function login(username: string, password: string): Promise<UserInfo> {
	const url = `${MAIN_ENDPOINT}/${AUTH_ENDPOINT.GET_TOKEN}`;
	const { data } = await axios.post<UserInfo>(url, {
		username,
		password,
	});
	return data;
}
