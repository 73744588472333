import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { increment, decrement } from '../../store/fooSlice';
import { fetchTodos } from '../../store/todosSlice';

function Test(): React.ReactElement {
	const foo = useSelector((state: RootState) => state.foo);
	const todos = useSelector((state: RootState) => state.todos);

	const dispatch = useDispatch();
	const [amount, setAmount] = useState(0);

	return (
		<div className="padding--8">
			<h1>Current {foo}</h1>
			<div>{JSON.stringify(todos)}</div>
			<input
				type="number"
				onChange={e => setAmount(Number(e.target.value))}
			/>
			<button type="button" onClick={() => dispatch(fetchTodos())}>
				OK
			</button>
			<button type="button" onClick={() => dispatch(increment(amount))}>
				Increase
			</button>
			<button type="button" onClick={() => dispatch(decrement(amount))}>
				Decrease
			</button>
		</div>
	);
}

export default Test;
