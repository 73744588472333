import React, { useEffect, useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GenericContainer from '../../components/common/GenericContainer/GenericContainer';
import { PURCHASE_GUIDE_PAGE_ID } from '../../constants/configs';
import { Page } from '../../models/Page.d';
import { getPageByPageId } from '../../services/pageService';

import PurchaseImage1 from '../../assets/images/content/payment-1.svg';
import PurchaseImage2 from '../../assets/images/content/payment-2.svg';
import PurchaseImage3 from '../../assets/images/content/payment-3.svg';

import Account from '../../assets/images/accounts/earn-acc-num.svg';
import PromptPay from '../../assets/images/accounts/earn-qr-pp.png';

import './PurchaseView.scss';
import Button from '../../components/common/Button/Button';
// import { doNothing } from '../../utils/fetchUtils';
import { Paths } from '../../router/routes';
import { RootState } from '../../store';

function PurchaseView(): React.ReactElement {
	// const [purchaseGuide, setPurchaseGuide] = useState<Page>();
	const [isShowingDetails, setIsShowingDetails] = useState(false);
	const [copyText, setCopyText] = useState('คัดลอก');
	const userState = useSelector((state: RootState) => state.auth)?.userState;
	const history = useHistory();

	const isLoggedIn = userState === 'loggedIn';

	// useEffect(() => {
	// 	getPageByPageId(PURCHASE_GUIDE_PAGE_ID).then(setPurchaseGuide).catch(doNothing);
	// }, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [isShowingDetails]);

	const onSeePurchaseDetailsClick = (): void => {
		// if (!isLoggedIn) {
		// 	const toPath = generatePath(Paths.Register);
		// 	history.push(toPath);
		// }
		setIsShowingDetails(true);
	};

	const NextPage = (): React.ReactElement => (
		<GenericContainer>
			<div id="PurchaseView__NextPage">
				<h1>ชำระเงิน</h1>

				<div className="PurchaseView__Step">
					<div className="PurchaseView__Main" style={{ alignItems: 'center' }}>
						<h2 style={{ marginBottom: 0 }}>ขั้นตอนที่ 1</h2>
						<div>ชำระเงินค่าคอร์สเรียนตามราคารวมสุทธิด้วยวิธีการโอนเงินมาที่บัญชีต่อไปนี้</div>
					</div>
				</div>
				<div className="PurchaseView__Extra">
					<table>
						<tr>
							<th colSpan={2}>รายการสินค้า</th>
						</tr>
						<tr>
							<td>สินค้า</td>
							<td>ราคา</td>
						</tr>
						<tr>
							<td>สอวน. คณิตศาสตร์ - เรขาคณิต</td>
							<td>฿3,500</td>
						</tr>
						<tr style={{ display: 'none' }}>
							<td>ส่วนลดโปรโมชั่น (-28%)</td>
							<td>฿-1,001</td>
						</tr>
						<tr>
							<td>ราคารวมสุทธิ:</td>
							<td>฿3,500</td>
						</tr>
					</table>
				</div>

				<div className="AccountDetails">
					<div className="AccountImages">
						<img src={Account} />
						{/* <div>หรือ</div>
						<img src={PromptPay} /> */}
					</div>

					<div className="copyToClipboard">
						<input type="text" value="3332800873" id="accountInput" />
						<button type="button" onClick={copyToClipBoard} className="copyToClipboard">
							{copyText}
						</button>
					</div>
				</div>

				<div className="PurchaseView__Step">
					<div className="PurchaseView__Main">
						<h2>ขั้นตอนที่ 2</h2>
						<div>
							หลังจากที่ชำระเงินเรียบร้อยแล้ว ส่งหลักฐานการชำระเงินมาที่ Messenger ของ{' '}
							<a href="https://m.me/CourseLagoon">Facebook Page: CourseLagoon</a> เพื่อยืนยันการชำระเงิน
						</div>
					</div>
				</div>
				<div className="PurchaseView__Extra">
					<a href="https://m.me/CourseLagoon" className="margin--vertical-24">
						<Button classes="expanded" buttonText="ส่งข้อความ" />
					</a>
				</div>

				<div className="PurchaseView__Step">
					<div className="PurchaseView__Main">
						<h2>ขั้นตอนที่ 3</h2>
						<div>
							รอการตอบกลับจากทีมงานเพื่อยืนยันสถานะการชำระเงิน เมื่อการชำระเงินเรียบร้อยแล้ว
							ให้รอการตอบกลับจากทีมงานอีกครั้งเพื่อยืนยันสถานะการใช้งานคอร์สเรียนบนเว็บไซต์ภายใน 48
							ชั่วโมง
						</div>
					</div>
				</div>

				<div className="PurchaseView__Extra" style={{ marginTop: '4rem' }}>
					<Link to={Paths.Courses}>
						<Button buttonText="ดำเนินการเสร็จสิ้น" classes="filled blue margin--top-24 expanded" />
					</Link>
				</div>
			</div>
		</GenericContainer>
	);

	if (isShowingDetails) return <NextPage />;

	return (
		<div id="PurchaseView">
			<GenericContainer>
				<div id="PurchaseView__Content" style={{ textAlign: 'center' }}>
					<h1>วิธีการชำระเงิน</h1>
					<p>
						ในขณะนี้เว็บไซต์ CourseLagoon กำลังอยู่ระหว่างการพัฒนาเว็บไซต์ให้เสร็จสมบูรณ์
						รวมถึงพัฒนาระบบชำระเงินในรูปแบบต่าง ๆ
						<br />
						ทำให้รูปแบบการชำระเงินแบบชั่วคราวในปัจจุบันเป็นการโอนเงินเข้าบัญชี
						โดยผู้ใช้งานสามารถชำระเงินเพื่อซื้อคอร์สเรียนได้โดยวิธีดังต่อไปนี้
					</p>
					<div className="PurchaseView__Icons">
						<div className="PurchaseGuideCard">
							<div className="PurchaseGuideCard__Background">
								<img src={PurchaseImage1} />
							</div>
							<div className="PurchaseGuideCard__Text">ชำระเงินค่าคอร์สโดยการโอนเงินเข้าบัญชีธนาคาร</div>
						</div>
						<div className="PurchaseGuideCard">
							<div className="PurchaseGuideCard__Background">
								<img src={PurchaseImage2} />
							</div>
							<div className="PurchaseGuideCard__Text">
								ส่งหลักฐานการชำระเงินมาที่ Messenger
								<br />
								ของ <a href="https://m.me/CourseLagoon">Facebook Page: CourseLagoon</a>
							</div>
						</div>
						<div className="PurchaseGuideCard">
							<div className="PurchaseGuideCard__Background">
								<img src={PurchaseImage3} />
							</div>
							<div className="PurchaseGuideCard__Text">
								รอการตอบกลับยืนยันสถานะการชำระเงิน
								<br />
								และสถานะการเปิดให้เข้าเรียนคอร์สภายใน 48 ชั่วโมง
							</div>
						</div>
					</div>

					<Button
						buttonText="ดำเนินการชำระเงิน"
						classes="margin--top-24 filled blue"
						onClick={onSeePurchaseDetailsClick}
					/>
				</div>
				{/* {purchaseGuide && <div dangerouslySetInnerHTML={{ __html: purchaseGuide?.content.rendered }} />} */}
			</GenericContainer>
		</div>
	);

	function copyToClipBoard(): void {
		const inputRef = document.getElementById('accountInput') as HTMLInputElement;

		if (!inputRef) return;

		/* Select the text field */
		inputRef.select();
		inputRef.setSelectionRange(0, 99999); /* For mobile devices */

		/* Copy the text inside the text field */
		document.execCommand('copy');

		setCopyText('✔︎ คัดลอกแล้ว');
	}
}

export default PurchaseView;
