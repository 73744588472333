import React, { useState } from 'react';
import Markdown from '../../../components/common/Markdown/Markdown';

import './Reading.scss';

const mdFile = require('../../../components/common/Markdown/data.md');

function Reading(): React.ReactElement {
	const [mdContent, setMdContent] = useState('');
	getMdContent();

	return (
		<div id="reading-view">
			<div id="lesson-navigation">Sidebar</div>
			<div id="reading-content">
				<Markdown className="padding--8" content={mdContent} />
			</div>
		</div>
	);

	function getMdContent(): void {
		fetch(mdFile)
			.then(res => res.text())
			.then(setMdContent)
			.catch(() => setMdContent('Could not load'));
	}
}

export default Reading;
