import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import Button from '../../components/common/Button/Button';
import CourseCard from '../../components/common/CourseCard/CourseCard';
import GenericContainer from '../../components/common/GenericContainer/GenericContainer';
import Loading from '../../components/common/Loading/Loading';
import { Course } from '../../models/Course.d';
import { Paths } from '../../router/routes';
import { getCoursesByUserId } from '../../services/courseService';
import { RootState } from '../../store';

import './MyCoursesView.scss';

function MyCourses(): React.ReactElement {
	const userId = useSelector((state: RootState) => state.user)?.me?.id;
	const token = useSelector((state: RootState) => state.auth)?.userInfo?.token;
	const users = useSelector((state: RootState) => state.user)?.users;

	const [courses, setCourses] = useState<Course[]>();

	useEffect(() => {
		if (!userId || !token) return;

		getCoursesByUserId(userId, token)
			.then(({ data }) => setCourses(data))
			.catch(() => {});
	}, [userId, token]);

	const getPath = (courseId: number): string =>
		generatePath(Paths.Course, {
			courseId,
		});

	return (
		<div id="MyCoursesView">
			<GenericContainer isNarrow>
				<h1>คอร์สของฉัน</h1>

				{courses && courses?.length && (
					<div className="margin--bottom-40">จำนวนคอร์สที่ลงทะเบียน: {courses?.length} คอร์ส</div>
				)}

				{!courses && <Loading />}

				{courses && !courses?.length && (
					<div>
						<div style={{ marginBottom: '4rem' }}>ขออภัย คุณยังไม่มีคอร์ส</div>
						<Link to={Paths.Courses}>
							<Button buttonText="ดูคอร์สทั้งหมด" classes="filled blue" />
						</Link>
					</div>
				)}

				{courses?.map(c => {
					const tutor = users.find(u => u.id === c.author);
					return (
						<Link key={c.id} to={getPath(c.id)} className="link">
							<CourseCard
								title={c.title.rendered}
								author={tutor?.name ?? 'CourseLagoon'}
								hidePrice
								hideBadge
							/>
						</Link>
					);
				})}
			</GenericContainer>
		</div>
	);
}

export default MyCourses;
