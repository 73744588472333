/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Me, User } from '../models/User.d';

import { AppThunk } from '../reducers/rootReducer';
import * as userService from '../services/userService';

type UserSliceState = {
	me?: Me;
	users: User[];
};

const initialState: UserSliceState = {
	me: undefined,
	users: [
		{
			id: 1,
			name: 'Course Lagoon',
			url: 'https://cl.tmr.in.th',
			description: '',
			link: 'https://wp.courselagoon.com/author/courselagoon/',
			slug: 'courselagoon',
			avatar_urls: {
				'24': 'https://secure.gravatar.com/avatar/c827fa8c309632655c59aa4159e2a702?s=24&d=mm&r=g',
				'48': 'https://secure.gravatar.com/avatar/c827fa8c309632655c59aa4159e2a702?s=48&d=mm&r=g',
				'96': 'https://secure.gravatar.com/avatar/c827fa8c309632655c59aa4159e2a702?s=96&d=mm&r=g',
			},
			meta: [],
		},
		{
			id: 7,
			name: 'พี่เอิร์น',
			url: '',
			description: '',
			link: 'https://wp.courselagoon.com/author/earn_yolrada/',
			slug: 'earn_yolrada',
			avatar_urls: {
				'24': 'https://secure.gravatar.com/avatar/756d6cd7f6b7462d3d70201be70637d6?s=24&d=mm&r=g',
				'48': 'https://secure.gravatar.com/avatar/756d6cd7f6b7462d3d70201be70637d6?s=48&d=mm&r=g',
				'96': 'https://secure.gravatar.com/avatar/756d6cd7f6b7462d3d70201be70637d6?s=96&d=mm&r=g',
			},
			meta: [],
		},
	],
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setMe(state, action: PayloadAction<Me>) {
			// eslint-disable-next-line
			const { payload: _me } = action;
			const me = {
				id: _me.id,
				name: _me.name,
			};
			return {
				...state,
				me,
			};
		},
		setUsers(state, action: PayloadAction<User[]>) {
			const { payload: users } = action;
			return {
				...state,
				users,
			};
		},
	},
});

export const { setMe, setUsers } = userSlice.actions;

export const getUsers = (): AppThunk => async dispatch => {
	try {
		const users = await userService.getUsers();
		dispatch(setUsers(users));
	} catch (err) {
		// dispatch(getIssuesFailure(err.toString()));
	}
};

export const getMe =
	(token: string): AppThunk =>
	async dispatch => {
		try {
			const me = await userService.getMe(token);
			dispatch(setMe(me));
		} catch {
			// TODO
		}
	};

export default userSlice.reducer;
