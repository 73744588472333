/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from '../reducers/rootReducer';

const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('../reducers/rootReducer', () => {
		// eslint-disable-next-line global-require
		const hotReloadedReducer = require('../reducers/rootReducer').default;
		store.replaceReducer(hotReloadedReducer);
	});
}
export default store;
