/* eslint-disable react/no-danger */
import React from 'react';

function ContactView(): React.ReactElement {
	return (
		<iframe
			style={{ height: 'calc(100vh - 8.5rem)', width: '100%', border: 'none' }}
			title="contact"
			src="https://wp.courselagoon.com/contact"
		/>
	);
}

export default ContactView;
