import { AxiosError } from 'axios';
import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserRegistration } from '../../../models/User.d';
import { Paths } from '../../../router/routes';
import { register } from '../../../services/authService';
import { wordpressLogin } from '../../../store/authSlice';
import Checkbox from '../Form/Checkbox/Checkbox';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import Loading from '../Loading/Loading';
import Logo from '../Logo/Logo';

import './Register.scss';

export default function Register(): ReactElement {
	const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const [_password, _setPassword] = useState('');
	const [firstname, setFirstname] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [shouldShowCheckboxError, setShouldShowCheckboxError] = useState(false);
	const [isTaCAccepted, setIsTaCAccepted] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [errorText, setErrorText] = useState('');

	function handleError(err: AxiosError): void {
		setIsLoading(false);
		const text = getErrorText(err.response?.data?.code, err.response?.data?.message);
		setErrorText(text);
	}

	function onClickRegister(): void {
		const userRegistration: UserRegistration = {
			email,
			username,
			password,
			nickname: username,
			first_name: firstname,
			last_name: surname,
		};

		setShouldShowCheckboxError(!isTaCAccepted);
		if (!isTaCAccepted) {
			setErrorText('โปรดยอมรับข้อตกลงในการใช้งาน');
			return;
		}

		if (!email) setErrorText('โปรดใส่อีเมล');
		else if (!username) setErrorText('โปรดใส่ชื่อผู้ใช้');
		else if (!firstname) setErrorText('โปรดใส่ชื่อจริง');
		else if (!surname) setErrorText('โปรดใส่นามสกุล');
		else if (!password || !_password) setErrorText('โปรดใส่รหัสผ่าน');
		else if (password !== _password) setErrorText('โปรดใส่รหัสผ่านให้ตรงกัน');
		else if (password.length < 8) setErrorText('โปรดใช้รหัสผ่านที่มีความยาวมากกว่า 8 ตัวอักษร');

		if (!email || !username || !password || !_password || !firstname || !surname || password.length < 8) return;

		setErrorText('');

		setIsLoading(true);

		register(userRegistration)
			.then(res => {
				console.log(res.data);
				dispatch(wordpressLogin(username, password));
			})
			.catch(handleError);
	}

	function onCheckBoxClicked(e: React.ChangeEvent<HTMLInputElement>): void {
		setIsTaCAccepted(e.target.checked);
		setShouldShowCheckboxError(!e.target.checked);
	}

	function getErrorText(errorCode?: number, message?: string): string {
		if (errorCode === 4001) return 'โปรดใส่ชื่อผู้ใช้';
		if (errorCode === 4002) return 'โปรดใส่รหัสผ่าน';
		if (errorCode === 4003) return 'โปรดใส่อีเมล';
		if (errorCode === 40011) return 'มีชื่อผู้ใช้นี้ในระบบแล้ว โปรดใช้ชื่ออื่น';
		if (errorCode === 40031) return 'มีชื่ออีเมลนี้ในระบบแล้ว โปรดใช้อีเมลอื่น';
		if (errorCode === 40032) return 'อีเมลที่กรอกไม่ถูกต้อง โปรดลองอีกครั้ง';
		return message ?? 'ข้อผิดพลาดที่ไม่คาดคิด';
	}

	return (
		<div id="register-component">
			<Logo withWordmark height="72px" wordMarkHeight="56px" />
			<h1 id="register-component__title" className="text--18 text--semi-bold">
				ลงทะเบียนเข้าใช้ CourseLagoon
			</h1>

			<HorizontalLine width="144px" />

			<div className="margin--top-16" style={{ width: '100%' }}>
				<input
					type="text"
					placeholder="ชื่อผู้ใช้ (Username)"
					onChange={e => setUsername((e.target as HTMLInputElement).value)}
				/>

				<div id="register-component__names">
					<input
						className="no-icon"
						type="text"
						placeholder="ชื่อ"
						onChange={e => setFirstname((e.target as HTMLInputElement).value)}
					/>
					<input
						className="no-icon"
						type="text"
						placeholder="นามสกุล"
						onChange={e => setSurname((e.target as HTMLInputElement).value)}
					/>
				</div>

				<input
					className="icon icon--email"
					type="email"
					placeholder="อีเมล"
					onChange={e => setEmail((e.target as HTMLInputElement).value)}
				/>
				<input
					className="icon icon--unlocked"
					type="password"
					placeholder="รหัสผ่าน"
					onChange={e => setPassword((e.target as HTMLInputElement).value)}
				/>
				<input
					className={`icon icon--checked ${password !== _password && !!_password ? 'error' : ''}`}
					type="password"
					placeholder="ยืนยันรหัสผ่าน"
					onChange={e => _setPassword((e.target as HTMLInputElement).value)}
				/>

				<Checkbox
					text={renderCheckboxText()}
					checkboxClass={shouldShowCheckboxError ? 'error' : ''}
					id="register-component__tos-check"
					onChecked={onCheckBoxClicked}
				/>

				{errorText && (
					<div className="errorText">
						ขออภัย โปรดลองอีกครั้ง
						<br />({errorText})
					</div>
				)}

				{isLoading ? (
					<div className="flex flex--justify-center">
						<Loading />
					</div>
				) : (
					<button type="button" onClick={onClickRegister}>
						ลงทะเบียน
					</button>
				)}

				<div id="register-component__login" className="margin--top-16 text--14">
					มีบัญชีผู้ใช้แล้ว?&nbsp;
					<Link to={Paths.Login} className="link">
						คลิกเพื่อเข้าสู่ระบบ
					</Link>
				</div>
			</div>
		</div>
	);
}

function renderCheckboxText(): ReactElement {
	const termsLink = (
		<Link to={Paths.ToC} className="link">
			ข้อกำหนดและเงื่อนไขในการใช้งาน
		</Link>
	);

	// const privacyPolicyLink = (
	// 	<Link to={Paths.ToC} className="link">
	// 		นโยบายความเป็นส่วนตัว
	// 	</Link>
	// );

	return (
		<>
			ฉันยอมรับใน {termsLink}
			{/* และ {privacyPolicyLink} */}
			ของ CourseLagoon
		</>
	);
}
