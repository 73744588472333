/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../reducers/rootReducer';
import { getTodos } from '../services/todoService';

type TodosSliceState = any[];

const initialState: TodosSliceState = [];

const todosSlice = createSlice({
	name: 'todos',
	initialState,
	reducers: {
		getTodosSuccess(state, action: PayloadAction<any>) {
			const { payload: todos } = action;
			return todos;
		},
	},
});

export const { getTodosSuccess } = todosSlice.actions;

export const fetchTodos = (): AppThunk => async dispatch => {
	try {
		// dispatch(getIssuesStart());
		const todos = await getTodos();
		dispatch(getTodosSuccess(todos));
	} catch (err) {
		// dispatch(getIssuesFailure(err.toString()));
	}
};

export default todosSlice.reducer;
