import React, { useEffect } from 'react';
import { matchPath, Switch, useLocation } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { useDispatch, useSelector } from 'react-redux';

import { routeConfig } from './router/router';
import { Paths } from './router/routes';
import NavBar from './components/common/NavBar/NavBar';

import { checkToken } from './store/authSlice';
import { initialLoad } from './services/bootstrapService';
import { RootState } from './store';
import LoadingView from './views/LoadingView/LoadingView';
import Login from './views/Login/Login';

import './App.scss';

function App(): React.ReactElement {
	const dispatch = useDispatch();
	const currentPath = useLocation()?.pathname as Paths;
	const userState = useSelector((state: RootState) => state.auth)?.userState;
	const isShowingLoadingScreen = useSelector((state: RootState) => state.app)?.loadingScreenCount > 0 ?? true;

	useEffect(() => {
		dispatch(checkToken());
		initialLoad();
	}, [userState, dispatch]);

	const shouldRenderHeader = ![Paths.Login, Paths.Register].includes(currentPath);
	const forceLogInPaths = [Paths.Lesson, Paths.MyCourses];
	const shouldForceLogIn = forceLogInPaths
		.map(path => !!matchPath(currentPath, { path, strict: true }))
		.reduce((p, c) => p || c);

	if (userState === undefined || isShowingLoadingScreen) return <LoadingView />;

	return userState !== 'loggedIn' && shouldForceLogIn ? (
		<Login />
	) : (
		<>
			{shouldRenderHeader && RenderHeader()}
			<Switch>{renderRoutes(routeConfig)}</Switch>
		</>
	);
}

function RenderHeader(): React.ReactElement {
	return (
		<header id="AppHeader">
			<NavBar />
		</header>
	);
}

export default App;
