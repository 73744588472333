import React from 'react';
import * as styles from './Loading.module.scss';

function Loading(): React.ReactElement {
	return (
		<div className={styles.loading}>
			<div />
			<div />
			<div />
			<div />
		</div>
	);
}

export default Loading;
