import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, matchPath, generatePath, useHistory } from 'react-router-dom';
import LoginComponent from '../../components/common/Login/Login';
import { Paths } from '../../router/routes';
import { RootState } from '../../store';

import './Login.scss';

function Login(): React.ReactElement {
	const currentPath = useLocation()?.pathname as Paths;
	const userState = useSelector((state: RootState) => state.auth)?.userState;
	const history = useHistory();

	useEffect(() => {
		const isLogInView = !!matchPath(currentPath, { path: Paths.Login, strict: true });
		const isLoggedIn = userState === 'loggedIn';
		if (isLoggedIn && isLogInView) {
			const toPath = generatePath(Paths.MyCourses);
			history.push(toPath);
		}
	}, [currentPath, history, userState]);

	return (
		<div id="login-view">
			<LoginComponent />
		</div>
	);
}

export default Login;
