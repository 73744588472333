import React from 'react';
import Loading from '../../components/common/Loading/Loading';

import './LoadingView.scss';

function Login(): React.ReactElement {
	return (
		<div id="loadingView">
			<Loading />
		</div>
	);
}

export default Login;
