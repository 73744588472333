import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';
import RegisterComponent from '../../components/common/Register/Register';
import { Paths } from '../../router/routes';
import { RootState } from '../../store';

import './Register.scss';

function Register(): React.ReactElement {
	const currentPath = useLocation()?.pathname as Paths;
	const userState = useSelector((state: RootState) => state.auth)?.userState;
	const history = useHistory();

	useEffect(() => {
		const isRegisterView = !!matchPath(currentPath, { path: Paths.Register, strict: true });
		const isLoggedIn = userState === 'loggedIn';
		if (isLoggedIn && isRegisterView) {
			const toPath = generatePath(Paths.MyCourses);
			history.push(toPath);
		}
	}, [currentPath, history, userState]);

	return (
		<div id="register-view">
			<RegisterComponent />
		</div>
	);
}

export default Register;
